#path-1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
}

#path-2 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 0.15s;
}

#path-3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 0.40s;
}

#path-4 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 0.60s;
}

#path-5 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 0.75s;
}

#path-6 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
}

#path-7 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 1.4s;
}

#path-8 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 0.75s normal forwards;
  animation-iteration-count: 1;
  animation-delay: 1.6s;
}

@keyframes draw {
  /* from {
    stroke-dashoffset: 1000;
  } */
  to {
    stroke-dashoffset: 0;
  }
}
