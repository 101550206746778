html, body {
  overflow: hidden;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#linkedin, #github, #resume {
  color: #6e7179;
}

li {
  padding: 0;
  text-align: center;
}

li:hover .svg-inline--fa {
  color: white;
}

li a {
  display: block; padding: 1em;
}

#logo-li {
  padding: 0.42em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
