.skill-container {
    position: relative;
}

.skill-image {
    width: 50px;
    height: 50px;
    padding: 1em;
    transition: transform .2s;
}

.skillset-divider {
    width: 80%;
    background-color: white;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2em;
}

.skill-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: transparent;
}

.skill-container:hover .skill-overlay {
    opacity: 1;
}

.skill-container:hover .skill-image {
    transform: scale(1.5);
    opacity: 0.23;
}

.text {
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

.hr-container {
    width: 100%;
}

#skillset p {
    padding-left: 2em;
}

#stars-div {
    text-align: center;
}

.fa-xs {
    font-size: 0.6em;
}