.job {
    padding: 1em 0 0 2em;
    text-align: left;
}

.job p {
    padding-bottom: 1em;
}

.job-header {
    text-align: left;
}

.job-title {
    margin: 0;
    padding-bottom: 0.1em;
}

.job-company, .job-tenure {
    margin: 0;
    padding-bottom: 0.2em;
}

.job-body {
    width: 95%;
}

.job-divider {
    width: 95%;
    margin-left: 0;
    margin-right: auto;
    background-color: white;
}

a {
    color: #61dafb
}