.App {
  text-align: left;
}

::-webkit-scrollbar {
  display: none;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

#work-experience, #skillset, #profile, #omscs {
  overflow-y: scroll;
  max-height: 100vh;
}

h1 {
  padding: 0 0 0.5em 1em;
  margin-bottom: 0;
}

.spacer {
  height: 1.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
